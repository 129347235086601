<template>
    <Page icon="mdi-office-building" :title="form.name" :progress="progress" :error="error" @reload="load()">
        <ActionBar :updateTime="updateTime">
                <v-btn 
                    color="primary"
                    @click="save()"
                    :loading="saveProgress" 
                    :disabled="saveProgress || dangerProgress"
                >
                    <v-icon left>mdi-content-save-outline</v-icon> {{$t("save")}}
                </v-btn>
        </ActionBar>

        <Card class="mt-3" :title="$t('entity.update.title')">
            <v-text-field
                :label="$t('entity.form.name')"
                v-model="form.name"
                :error-messages="errors.name"
                :hide-details="!errors.name"
                :disabled="saveProgress || dangerProgress"
                @change="validate('name')"
                outlined
                prepend-inner-icon="mdi-office-building"
            />

            <v-text-field
                class="mt-3"
                :label="$t('entity.form.namecompany')"
                v-model="form.company.name"
                :error-messages="errors.company.name"
                :hide-details="!errors.company.name"
                :disabled="saveProgress || dangerProgress"
                @change="validate('company.name')"
                outlined
                prepend-inner-icon="mdi-form-textbox"
            />

            <v-text-field
                class="mt-3"
                :label="$t('entity.form.address')"
                v-model="form.company.address"
                :error-messages="errors.company.address"
                :hide-details="!errors.company.address"
                :disabled="saveProgress || dangerProgress"
                @change="validate('company.address')"
                outlined
                prepend-inner-icon="mdi-map-marker"
            />

            <v-row>
                <v-col>
                    <v-text-field
                        class="mt-3"
                        :label="$t('entity.form.nip')"
                        v-model="form.company.nip"
                        :error-messages="errors.company.nip"
                        :hide-details="!errors.company.nip"
                        :disabled="saveProgress || dangerProgress"
                        @change="validate('company.nip')"
                        outlined
                        prepend-inner-icon="mdi-music-accidental-sharp"
                    />
                </v-col>

                <v-col>
                    <v-text-field
                        class="mt-3"
                        :label="$t('entity.form.phone')"
                        v-model="form.company.phone"
                        :error-messages="errors.company.phone"
                        :hide-details="!errors.company.phone"
                        :disabled="saveProgress || dangerProgress"
                        @change="validate('company.phone')"
                        outlined
                        prepend-inner-icon="mdi-phone"
                    />
                </v-col>
            </v-row>
        </Card>

        <Card class="mt-3" :title="$t('entity.update.terms')">
            <v-tabs class="elevation-0 mb-2">
                <template v-for="(locale, index) in Object.keys(form.terms)" >
                    <v-tab :key="index" @click="selected_terms_lang = locale">{{$t("langs."+locale)}}</v-tab>
                </template>
            </v-tabs>

            <Editor
                v-model="form.terms[selected_terms_lang]"
                api-key="xwep2qfghqcenjgm40y2sny6s3i3lhocrmbhzd4sblmfllbi"
                :init="{
                    height: 500,
                    menubar: false,
                    language: $i18n.locale,
                    plugins: [
                    'advlist autolink lists link image charmap print preview anchor',
                    'searchreplace visualblocks code fullscreen',
                    'insertdatetime media table paste code help wordcount'
                    ],
                    toolbar:
                    'undo redo | formatselect | bold italic backcolor | \
                    alignleft aligncenter alignright alignjustify | \
                    bullist numlist outdent indent | removeformat | help'
                }"
            />
        </Card>

        <DangerZone
            v-if="user.entity._id != form.id && user.hasPerm('user') && user.hasPerm('role') && user.hasPerm('patient') && user.hasPerm('device') && user.hasPerm('video')"
            @btnClick="beforeDangerZoneAction()"
            v-model="dialogDangerItem"
            :data="[
                {
                    itemKey: 'changeStatus',
                    itemName: $t('entity.danger_zone.change_status'),
                    itemInfo: $t('entity.danger_zone.change_status_info') + ' ',
                    itemInfoGreen: (form.enabled ? $t('active') : null),
                    itemInfoRed: (form.enabled ? null : $t('inactive')),
                    itemProgress: (dialogDangerItem && dialogDangerItem.itemKey == 'changeStatus' ? dangerProgress : false)
                },

                {
                    itemKey: 'delete',
                    itemName: $t('entity.danger_zone.delete'),
                    itemInfo: $t('entity.danger_zone.delete_info'),
                    itemProgress: (dialogDangerItem && dialogDangerItem.itemKey == 'delete' ? dangerProgress : false)
                }
            ]"
            :disabled="saveProgress || dangerProgress"
        />

        <DangerZoneConfirm
            v-if="dialogDanger"
            :title="() => {switch (dialogDangerItem.itemKey) {
                case 'changeStatus': 
                    return $t('entity.danger_zone.change_status');
                    break;
                case 'delete':
                    return $t('entity.danger_zone.delete');
            }}"
            :description="() => {switch (dialogDangerItem.itemKey) {
                case 'changeStatus': 
                    return $t('entity.danger_zone.dialog.change_status_desc');
                    break;
                case 'delete':
                    return $t('entity.danger_zone.dialog.delete_desc');
            }}"
            :descriptionContent="() => {switch (dialogDangerItem.itemKey) {
                case 'changeStatus': 
                    return (form.enabled ? $t('inactive') : $t('active'));
                    break;
                case 'delete':
                    return form.name;
            }}"
            :checkText="() => form.name"
            :dependenciesData="dependenciesData" 
            :open="dialogDanger"
            :save="dialogDangerItem.itemName"
            :saveProgress="dangerDialogSaveProgress"
            @cancel="dialogDanger = false" 
            @save="dangerZoneAction(dialogDangerItem)"
        />

        <SaveSnackBar v-model="save_snackbar" :text="$t('save_snackbar')" />
    </Page>
</template>

<script>
import Editor from '@tinymce/tinymce-vue';
import Page from "./components/Page.vue";
import ActionBar from "./components/ActionBar.vue";
import Card from "./components/Card.vue";
import DangerZone from "./components/DangerZone.vue";
import DangerZoneConfirm from "./dialogs/DangerZoneConfirm.vue";
import RoleCreate from "./dialogs/RoleCreate.vue";
import UserCreate from "./dialogs/UserCreate.vue";
import validator from "../plugins/validator";
import * as entityValidation from "../api/entity/validation";
import SaveSnackBar from "./components/SaveSnackBar.vue";

export default {
    components: {
        Editor,
        Page,
        ActionBar,
        Card,
        RoleCreate,
        UserCreate,
        DangerZone,
        DangerZoneConfirm,
        SaveSnackBar
    },

    async mounted() {
        this.form.id = this.$route.params.id;

        for(let locale of Object.keys(this.$i18n.messages)){
            this.form.terms[locale] = null;
        }

        this.selected_terms_lang = this.$i18n.locale;
        this.load();
    },

    data: () => ({
        progress: true,
        saveProgress: false,
        dangerProgress: false,
        dangerDialogSaveProgress: false,

        dialogRole: false,
        dialogUser: false,
        dialogDanger: false,
        dialogDangerItem: null,
        dependenciesData: null,

        selected_terms_lang: null,

        form: {
            id: null,
            name: null,
            enabled: null,
            company: {
                name: null,
                address: null,
                nip: null,
                phone: null
            },
            terms: {}
        },

        errors: {
            name: null,
            company: {
                name: null,
                address: null,
                nip: null,
                phone: null
            }
        },

        error: {
            type: null,
            msg: null,
            code: null
        },

        updateTime: null,

        save_snackbar: false
    }),

    computed: {
        user() {
            return this.$store.state.api.user.data;
        }
    },

    methods: {
        async load() {
            if(this.error.type){
                this.progress = true;
                this.error = {
                    type: null,
                    msg: null,
                    code: null
                }
            }

            const res = await this.$store.dispatch("api/entity/get", { id: this.form.id });

            if (res.status == 200) {
                let entity = res.data.entity;

                this.form.name = entity.name;
                this.form.enabled = entity.enabled;
                this.form.company.name = entity.company.name;
                this.form.company.address = entity.company.address;
                this.form.company.nip = entity.company.nip;
                this.form.company.phone = entity.company.phone;
                this.updateTime = entity.updateTime ? new Date(entity.updateTime) : null;

                for(let locale of Object.keys(this.$i18n.messages)){
                    this.form.terms[locale] = (entity.terms && entity.terms[locale]) ? entity.terms[locale] : null;
                }
                
                this.progress = false;
            } else {
                this.error = {
                    type: "network",
                    msg: res.data.error.code,
                    code: res.status
                }
            }
        },

        validate(check) {
            if(check) {
                let splitted = check.split('.');
                if(splitted.length > 1) this.errors[splitted[0]][splitted[1]] = null;
                else this.errors[check] = null;
            }else Object.assign(this.$data.errors, this.$options.data().errors);

            return validator(
                this.form,
                entityValidation.update,
                function(key, type, limit) {
                    if(check && key != check) return;
                    if(key.includes("company.")){
                        key = key.replace("company.", "");
                        let text = this.$t(`form_errors.${key}.${type}`);
                        this.errors.company[key] = text;
                    }else{
                        let text = this.$t(`form_errors.${key}.${type}`);
                        this.errors[key] = text;
                    }
                }.bind(this)
            );
        },

        async save() {
            if(!this.validate()) return;

            this.saveProgress = true;
            await this.$store.dispatch("api/entity/update", this.form);
            await this.load();
            this.saveProgress = false;
            this.save_snackbar = true;
        },

        async beforeDangerZoneAction() {
            this.dangerProgress = true;
            await this.load();
            this.dependenciesData = null;
            this.dangerProgress = false;
            this.dialogDanger = true;
        },

        async dangerZoneAction(item) {
            switch (item.itemKey) {
                case "delete":
                    this.dangerDialogSaveProgress = true;
                    this.dependenciesData = null;

                    const res = await this.$store.dispatch("api/entity/delete", { id: this.form.id });
                    if (res.status==405) {
                        this.dependenciesData = res.data.data;
                        this.dangerDialogSaveProgress = false;
                        return;
                    } else if (res.status==200) {
                        this.$store.dispatch('api/user/selectEntity', this.$store.state.api.user.data.entity);
                        this.$router.push({ name: 'entities' });
                    } else {
                        this.error = {
                            type: "network",
                            msg: res.data.error.code,
                            code: res.status
                        }
                        this.dialogDanger = false;
                        this.dangerDialogSaveProgress = false;
                    }
                    break;
                case "changeStatus":
                    this.dangerDialogSaveProgress = true;
                    let tempForm = Object.assign({}, this.form);
                    tempForm.enabled = !this.form.enabled;
                    await this.$store.dispatch("api/entity/update", tempForm);
                    await this.load();
                    this.dialogDanger = false;
                    this.dangerDialogSaveProgress = false;
                    break;
            }
        }
    }
};
</script>
