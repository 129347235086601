<template>
    <Dialog :title="$t('role.create.title')" :open="open" :saveProgress="saveProgress" @cancel="$emit('cancel')" @save="save()">
        <v-text-field 
            v-model="form.name"
            :label="$t('role.form.name')" 
            :error-messages="errors.name"
            :hide-details="!errors.name"
            :disabled="saveProgress"
            @change="validate('name')"
            outlined
            prepend-inner-icon="mdi-account-group"
        />
    </Dialog>
</template>

<script>
import Dialog from "../components/Dialog.vue";
import validator from "../../plugins/validator";
import * as roleValidation from "../../api/role/validation";
import { object } from 'joi';

export default {
    components: {
        Dialog
    },

    props: {
        open: {
            type: Boolean,
            default: false
        },

        title: {
            type: String,
            default: null
        },

        entity: {
            type: String,
            default: null
        }
    },

    data: () => ({
        form: {
            name: null
        },

        errors: {
            name: null
        },
        
        saveProgress: false
    }),

    methods: {
        validate(check) {
            if(check) this.errors[check] = null;
            else Object.assign(this.$data.errors, this.$options.data().errors);

            return validator(
                this.form,
                roleValidation.create,
                function(key, type, limit) {
                    if(check && key != check) return;
                    let text = this.$t(`form_errors.${key}.${type}`);
                    this.errors[key] = text;
                }.bind(this)
            );
        },

        async save() {
            if (!this.validate()) return;
            if(this.entity) this.form.entity = this.entity;
            
            this.saveProgress = true;
            let res = await this.$store.dispatch("api/role/create", this.form);
            this.saveProgress = false;

            if (res.status == 200) {
                this.$emit("save");
                this.$emit("cancel");
            }else{
                if(res.data.error.code == "RoleExists") {
                    this.errors.name = this.$t("role.create.create_exists");
                } else {
                    this.errors.name = res.data.error.message;
                }
            }
        }
    }
};
</script>
